import React, { Fragment } from 'react';
import boxnshipstock from '../assets/img/boxnship-stock-image.png';
import Footer from '../components/footer';
import useMobile from '../hooks/useMobile';
import { ModulesComponent } from '../components/mainpage/section-1';
import {
  CustomPackingLoginIcon,
  DigitalAssistanceIcon,
  DigitalPodIcon,
  DynamicBoxPriningIcon,
  ShipmentIcon,
} from '../assets/icons';
import { useHistory } from 'react-router-dom';
import WhatsAppChat from '../components/whatsup-link';

const TEXT_RIGHT = 'text-right';
const JUSTIFY_CONTENT_END = 'justify-content-end';
const JUSTIFY_CONTENT_CENTER = 'justify-content-center';
export const ProductStepIcon = ({ alignment, icon }) => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div
      className={`d-flex align-item-start ${
        !isMobile && alignment === 'right'
          ? JUSTIFY_CONTENT_END
          : !isMobile && alignment === 'left'
          ? ''
          : JUSTIFY_CONTENT_CENTER
      }`}
    >
      {icon}
    </div>
  );
};
const BoxNShipImage = ({ alignment, isMobile }) => {
  return (
    <div
      className={`d-flex ${
        isMobile
          ? 'justify-content-center'
          : alignment === 'right'
          ? JUSTIFY_CONTENT_END
          : alignment === 'left'
          ? ''
          : JUSTIFY_CONTENT_CENTER
      }`}
      style={{ maxHeight: '100%' }}
    >
      <img
        style={{
          maxHeight: '100%',
          maxWidth: '100%',
          height: '400px',
          objectFit: 'contain',
          objectPosition: alignment,
        }}
        alt='boxnship'
        src={boxnshipstock}
      />
    </div>
  );
};
export const ShowModuleContent = ({ title, content, isMobile, isLeft }) => {
  const _isLeft = isMobile ? false : isLeft;

  return (
    <div
      className={`w-100 ${
        isMobile ? 'text-center' : _isLeft === false ? TEXT_RIGHT : ''
      }`}
    >
      <div
        className={`title d-flex ${
          isMobile ? JUSTIFY_CONTENT_CENTER : _isLeft ? '' : JUSTIFY_CONTENT_END
        }`}
      >
        {title}
      </div>
      <div
        className={` ${
          isMobile ? '' : _isLeft ? 'module-content-padded' : TEXT_RIGHT
        }`}
      >
        <div
          className={`content pt-3 ${!isMobile && !isLeft ? TEXT_RIGHT : ''}`}
        >
          {content}
        </div>
      </div>
    </div>
  );
};
export const ShowModule = ({
  isMobile,
  isLeft,
  valign,
  image,
  title,
  content,
}) => {
  const _isLeft = isMobile ? true : isLeft;
  const imageHeight =
    valign && valign === 'center' && !isMobile ? { height: '100%' } : {};
  const moduleImageWrpStyle =
    valign && valign === 'center' ? {} : { height: 'initial' };
  return (
    <Fragment>
      <div className='row'>
        <div
          className='col-12 col-md-6 module-image-wrp'
          style={moduleImageWrpStyle}
        >
          {_isLeft === true && (
            <div
              className={`w-100 ${
                isMobile ? 'text-center' : isLeft ? 'text-right' : 'text-left'
              }`}
              style={imageHeight}
            >
              {image}
            </div>
          )}
          {_isLeft === false && (
            <div
              className='w-100 d-flex align-items-center'
              style={imageHeight}
            >
              {content}
            </div>
          )}
        </div>
        <div
          className='col-12 col-md-6 module-image-wrp'
          style={moduleImageWrpStyle}
        >
          {_isLeft === false && <div style={imageHeight}>{image}</div>}
          {_isLeft === true && (
            <div className='d-flex align-items-center' style={imageHeight}>
              {content}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
const titleStyle = {
  paddingBottom: '16px',
  fontSize: '36px',
  fontColor: '#006EED',
  lineHeight: '42px',
};
const DigitalAssistanceToPacking = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={false}
          image={
            <ProductStepIcon
              alignment={'left'}
              isMobile={isMobile}
              isLeft={false}
              icon={<DigitalAssistanceIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={false}
              title={
                <div style={titleStyle}>Digital Assistance to Packing</div>
              }
              content='Pack by simply scanning the labels and ensure all the items of the order are packed.'
            />
          }
        />
      </div>
    </div>
  );
};
const CustomPackingLogic = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={true}
          image={
            <ProductStepIcon
              alignment={'right'}
              isMobile={isMobile}
              isLeft={true}
              icon={<CustomPackingLoginIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={true}
              title={<div style={titleStyle}>Custom Packing Logic</div>}
              content='Define your own custom packing logic to guide the operator in packing.'
            />
          }
        />
      </div>
    </div>
  );
};
const DynamicBoxLabel = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={false}
          image={
            <ProductStepIcon
              alignment={'left'}
              isMobile={isMobile}
              isLeft={false}
              icon={<DynamicBoxPriningIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={false}
              title={<div style={titleStyle}>Dynamic printing Box labels</div>}
              content='Print customized box labels for the packed boxes with a click of a button. You can also add your logo on the Box labels. '
            />
          }
        />
      </div>
    </div>
  );
};
const ShipErrorFree = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={true}
          image={
            <ProductStepIcon
              alignment={'right'}
              isMobile={isMobile}
              isLeft={true}
              icon={<ShipmentIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={true}
              title={<div style={titleStyle}>Shipments. Error Free</div>}
              content='No More boxes left behind in the factory.'
            />
          }
        />
      </div>
    </div>
  );
};
const DigitalPod = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={false}
          image={
            <ProductStepIcon
              alignment={'left'}
              isMobile={isMobile}
              isLeft={false}
              icon={<DigitalPodIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={false}
              title={<div style={titleStyle}>Digital POD</div>}
              content='Digitally Acknowledge the handover of the shipments '
            />
          }
        />
      </div>
    </div>
  );
};
const BoxNShip = () => {
  const history = useHistory();
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <Fragment>
      <div className='px-32'>
        <div className='section-2-wrp' style={{ padding: '0px' }}>
          <div className='section-2-content' style={{ paddingTop: '0px' }}>
            <div className='pb-4'>
              <ShowModule
                isMobile={isMobile}
                isLeft={true}
                valign={'center'}
                image={
                  <BoxNShipImage
                    alignment={'right'}
                    isMobile={isMobile}
                    isLeft={true}
                  />
                }
                content={
                  <ShowModuleContent
                    isMobile={isMobile}
                    isLeft={true}
                    title={<ModulesComponent name={'Box n Ship'} />}
                    content='Box items and Ship items, Error Free.'
                  />
                }
              />
            </div>
          </div>
          <DigitalAssistanceToPacking />
          <CustomPackingLogic />
          <DynamicBoxLabel />
          <ShipErrorFree />
          <DigitalPod />
          <WhatsAppChat />
        </div>
        <div className='py-64'>
          <div className='text-center'>
            <div
              className='btn btn-outline-primary cursor-pointer'
              style={{ minWidth: '400px' }}
              onClick={() => {
                history.push('/website/reachus');
              }}
            >
              Book a Demo
            </div>
          </div>
          <div className='text-center ' style={{ paddingTop: '32px' }}>
            <div
              className='btn btn-ghost cursor-pointer'
              style={{ minWidth: '400px' }}
              onClick={() => {
                history.push('/website');
              }}
            >
              Home
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default BoxNShip;
