import service from './index';

export const createLead = data => {
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi/leads/create`,
    data: {
      deleted: false,
      approved: true,
      active: true,
      req_payload: data,
    },
  });
};

export const getOrgImages = () => {
  return service({
    method: 'GET',
    url: `/naadi-app/naadi-web-service2/api/web/v1/org/listimages`,
    data: {},
  });
};
