import React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import outerStore from './store';
import App from './app';
import './scss/styles.scss';
const rootElem = document.getElementById('root');
const root = createRoot(rootElem);

const AppRoot = () => {
  return (
    <React.StrictMode>
      <Provider store={outerStore.store}>
        <PersistGate loading={null} persistor={outerStore.persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};
// console.log(outerStore);
root.render(<AppRoot />);
