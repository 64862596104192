import React, { useEffect } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import { Route } from 'react-router-dom';
import {
  faExclamationCircle,
  faUnlink,
} from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import 'react-toastify/dist/ReactToastify.css';
import { Message } from '@naadi/framework';
import Home from './home';
import Header from '../components/header';
import ReachUs from '../views/reach-us';
import ScreenLoader from '../components/screen-loader';
import { ToastContainer } from 'react-toastify';
import ProductsSubRouter from './products';

const Routes = () => {
  return (
    <div className=''>
      <div style={{ display: 'block', overflow: 'hidden' }}>
        <ScreenLoader />
      </div>
      <div className='main-panel d-block' style={{ minHeight: '100vh' }}>
        <Header />
        <Switch>
          <Route exact path='/website' component={Home} />
          <Route path='/website/reachus' exact component={ReachUs} />
          <Route path='/website/products/' component={ProductsSubRouter} />
          <Route path='/' exact component={Home} />
          <Route path='/401' exact component={Unauthorized} />
          <Route path='/redirect' exact component={Redirect} />
          <Route path='*' component={NotFound} />
        </Switch>
      </div>
      <ToastContainer />
    </div>
  );
};

const Unauthorized = () => (
  <Message
    heading={<FormattedMessage id='UNAUTHORIZED' />}
    icon={faExclamationCircle}
  >
    <FormattedMessage id='YOU-ARE-NOT-AUTHORIZED' />
  </Message>
);

const Redirect = () => {
  const location = useLocation();

  const history = useHistory();

  useEffect(() => {
    if (location?.state?.pathname) {
      history.push(location.state.pathname);
    }
  }, [location, history]);

  return (
    <Message heading={<FormattedMessage id='404' />} icon={faUnlink}>
      <FormattedMessage id='PAGE-NOT-FOUND' />
    </Message>
  );
};

const NotFound = () => {
  const location = useLocation();

  const history = useHistory();

  useEffect(() => {
    if (location.pathname.includes('&state')) {
      history.push({
        pathname: '/redirect',
        state: { pathname: location.pathname.split('&state')[0] },
      });
    }
  }, [location, history]);

  return (
    <Message heading={<FormattedMessage id='404' />} icon={faUnlink}>
      <FormattedMessage id='PAGE-NOT-FOUND' />
    </Message>
  );
};

export default Routes;
