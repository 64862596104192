import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppChat = () => {
  return (
    <div
      className='rounded-circle bg-success shadow-lg z-1'
      style={{
        padding: '10px',
        position: 'fixed',
        right: '30px',
        bottom: '50px',
        fontSize: '38px',
      }}
    >
      <a
        href='https://wa.me/+918050064727?text=Hi,%20Can%20I%20know%20more%20about%20your%20product%20%3F'
        className='whatsapp_float'
        target='_blank'
        rel='noopener noreferrer'
      >
        <FaWhatsapp className='text-white' />
      </a>
    </div>
  );
};

export default WhatsAppChat;
