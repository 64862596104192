import React, { Fragment } from 'react';
import boxnshiimage from '../../assets/img/boxnship-image.png';
import tracktraceimage from '../../assets/img/tracktrace-image.png';
import cutsmartimage from '../../assets/img/cutsmart-image.png';
import cacheimage from '../../assets/img/cache-image.png';

import useMobile from '../../hooks/useMobile';
import { ModulesComponent } from './section-1';
import { useHistory } from 'react-router-dom';
const TEXT_RIGHT = 'text-right';
const JUSTIFY_CONTENT_END = 'justify-content-end';
const JUSTIFY_CONTENT_CENTER = 'justify-content-center';

export const TrackTraceImage = ({ alignment }) => {
  return (
    <div
      className={`d-flex ${
        alignment === 'right'
          ? JUSTIFY_CONTENT_END
          : alignment === 'left'
          ? ''
          : JUSTIFY_CONTENT_CENTER
      }`}
      style={{ maxHeight: '100%' }}
    >
      <img
        style={{
          maxHeight: '100%',
          maxWidth: '100%',
          height: '400px',
          objectFit: 'contain',
          objectPosition: alignment,
        }}
        alt='tracktrace'
        src={tracktraceimage}
      />
    </div>
  );
};
export const BoxNShipImage = ({ alignment }) => {
  return (
    <div
      className={`d-flex ${
        alignment === 'right'
          ? JUSTIFY_CONTENT_END
          : alignment === 'left'
          ? ''
          : JUSTIFY_CONTENT_CENTER
      }`}
      style={{ maxHeight: '100%' }}
    >
      <img
        style={{
          maxHeight: '100%',
          maxWidth: '100%',
          height: '400px',
          objectFit: 'contain',
          objectPosition: alignment,
        }}
        alt='boxnship'
        src={boxnshiimage}
      />
    </div>
  );
};
export const CutSmartImage = ({ alignment }) => {
  return (
    <div
      className={`d-flex ${
        alignment === 'right'
          ? JUSTIFY_CONTENT_END
          : alignment === 'left'
          ? ''
          : JUSTIFY_CONTENT_CENTER
      }`}
      style={{ maxHeight: '100%' }}
    >
      <img
        style={{
          maxHeight: '100%',
          maxWidth: '100%',
          height: '400px',
          objectFit: 'contain',
          objectPosition: alignment,
        }}
        alt='CutSmart'
        src={cutsmartimage}
      />
    </div>
  );
};
export const CacheImage = ({ alignment }) => {
  return (
    <div
      className={`d-flex ${
        alignment === 'right'
          ? JUSTIFY_CONTENT_END
          : alignment === 'left'
          ? ''
          : JUSTIFY_CONTENT_CENTER
      }`}
      style={{ maxHeight: '100%' }}
    >
      <img
        style={{
          maxHeight: '100%',
          maxWidth: '100%',
          height: '400px',
          objectFit: 'contain',
          objectPosition: alignment,
        }}
        alt='Cache'
        src={cacheimage}
      />
    </div>
  );
};
const ShowModuleContent = ({
  title,
  content,
  isMobile,
  isLeft,
  knowMoreLink,
}) => {
  const history = useHistory();
  const _isLeft = isMobile ? false : isLeft;

  return (
    <div
      className={`${
        isMobile ? 'text-center' : _isLeft === false ? TEXT_RIGHT : ''
      }`}
    >
      <div
        className={`title d-flex ${
          isMobile ? JUSTIFY_CONTENT_CENTER : _isLeft ? '' : JUSTIFY_CONTENT_END
        }`}
      >
        {title}
      </div>
      <div
        className={` ${
          isMobile ? '' : _isLeft ? 'module-content-padded' : TEXT_RIGHT
        }`}
      >
        <div
          className={`content pt-3 ${!isMobile && !isLeft ? TEXT_RIGHT : ''}`}
        >
          {content}
        </div>
        <div
          className={`pt-3 ${
            !isMobile && !isLeft ? 'd-flex justify-content-end' : ''
          }`}
        >
          <div
            className='btn btn-outline-primary cursor-pointer'
            onClick={() => {
              if (knowMoreLink && knowMoreLink.length > 0) {
                history.push(knowMoreLink);
              }
            }}
          >
            Know More
          </div>
        </div>
      </div>
    </div>
  );
};
const ShowModule = ({ isMobile, isLeft, image, title, content }) => {
  const _isLeft = isMobile ? true : isLeft;
  return (
    <Fragment>
      <div className='row'>
        <div className='col-12 col-md-6 module-image-wrp'>
          {_isLeft === true && (
            <div
              className={`${
                isMobile ? 'text-center' : isLeft ? 'text-right' : 'text-left'
              }`}
              style={{ height: '100%' }}
            >
              {image}
            </div>
          )}
          {_isLeft === false && (
            <div
              className='d-flex align-items-center'
              style={{ height: isMobile ? 'initial' : '100%' }}
            >
              {content}
            </div>
          )}
        </div>
        <div className='col-12 col-md-6 module-image-wrp'>
          {_isLeft === false && <div style={{ height: '100%' }}>{image}</div>}
          {_isLeft === true && (
            <div
              className='d-flex align-items-center'
              style={{ height: isMobile ? 'initial' : '100%' }}
            >
              {content}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
const Section2 = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <Fragment>
      <div className='section-2-wrp'>
        <div className='section-2-header'>
          We’re creating products to make your manufacturing business smart.
        </div>
        <div className='section-2-content'>
          <div className='pb-4'>
            <ShowModule
              isMobile={isMobile}
              isLeft={true}
              image={
                <BoxNShipImage
                  alignment={'right'}
                  isMobile={isMobile}
                  isLeft={true}
                />
              }
              content={
                <ShowModuleContent
                  isMobile={isMobile}
                  isLeft={true}
                  title={<ModulesComponent name={'Box n Ship'} />}
                  knowMoreLink={'/website/products/boxnship'}
                  content='Naadi Box and Ship offers digital support to pack parts and hardwares into boxes and ship them efficiently.'
                />
              }
            />
          </div>
          <div className='pt-4 pb-4'>
            <ShowModule
              isMobile={isMobile}
              isLeft={false}
              image={<TrackTraceImage alignment={'left'} />}
              content={
                <ShowModuleContent
                  isMobile={isMobile}
                  isLeft={false}
                  title={<ModulesComponent name={'Track n Trace'} />}
                  knowMoreLink={'/website/products/tracktrace'}
                  content='Naadi Track and Trace Lite is a one-stop solution that delivers shop floor control, visibility, Quality Control and Reporting.'
                />
              }
            />
          </div>
          <div className='pt-4 pb-4'>
            <ShowModule
              isMobile={isMobile}
              isLeft={true}
              image={<CutSmartImage alignment={'left'} />}
              content={
                <ShowModuleContent
                  isMobile={isMobile}
                  isLeft={true}
                  title={<ModulesComponent name={'Cut Smart'} />}
                  knowMoreLink={'/website/products/cutsmart'}
                  content='Naadi Cut Smart provides diverse optimisation solutions for minimum wastage, easy handling, minimum time.'
                />
              }
            />
          </div>
          <div className='pt-4 pb-4'>
            <ShowModule
              isMobile={isMobile}
              isLeft={false}
              image={<CacheImage alignment={'left'} />}
              content={
                <ShowModuleContent
                  isMobile={isMobile}
                  isLeft={false}
                  title={<ModulesComponent name={'Cache'} />}
                  knowMoreLink={'/website/products/cache'}
                  content='Naadi Cache improves material usage by reducing waste and providing offcuts as inputs for optimisation.'
                />
              }
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Section2;
