import React, { Fragment } from 'react';
const OnTheCloudIcon = () => {
  return (
    <svg
      width='59'
      height='42'
      viewBox='0 0 59 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.3066 32.0234C27.2969 33.0332 25.7363 33.0332 24.7266 32.0234L18.8516 26.1484C17.8418 25.1387 17.8418 23.5781 18.8516 22.5684C19.8613 21.5586 21.4219 21.5586 22.4316 22.5684L26.5625 26.6074L36.4766 16.6934C37.4863 15.6836 39.0469 15.6836 40.0566 16.6934C41.0664 17.7031 41.0664 19.2637 40.0566 20.2734L28.3066 32.0234ZM13.3438 41.9375C6 41.9375 0.125 36.0625 0.125 28.7188C0.125 23.0273 3.79688 18.0703 8.9375 16.3262V16.2344C8.9375 7.78906 15.8223 0.8125 24.3594 0.8125C28.9492 0.8125 33.1719 2.92383 36.0176 6.22852C37.3945 5.58594 38.8633 5.21875 40.5156 5.21875C46.5742 5.21875 51.5312 10.1758 51.5312 16.2344C51.5312 16.877 51.4395 17.4277 51.3477 18.0703C55.7539 19.998 58.875 24.4043 58.875 29.4531C58.875 36.1543 53.6426 41.5703 47.125 41.9375H13.3438ZM24.3594 5.21875C18.209 5.21875 13.3438 10.1758 13.3438 16.2344V19.4473L10.3145 20.457C6.91797 21.6504 4.53125 24.9551 4.53125 28.7188C4.53125 33.584 8.38672 37.5312 13.3438 37.5312H46.5742H46.8496C51.0723 37.3477 54.4688 33.7676 54.4688 29.4531C54.4688 26.1484 52.4492 23.3027 49.6035 22.1094L46.4824 20.7324L47.0332 17.3359C47.0332 16.9688 47.125 16.6016 47.125 16.2344C47.125 12.6543 44.0957 9.625 40.5156 9.625C39.5059 9.625 38.5879 9.90039 37.7617 10.2676L34.8242 11.5527L32.6211 9.07422C30.6016 6.7793 27.6641 5.21875 24.2676 5.21875H24.3594Z'
        fill='#027AFF'
      />
    </svg>
  );
};
const PlugAndPlayIcon = () => {
  return (
    <svg
      width='59'
      height='36'
      viewBox='0 0 59 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M36.8438 19.8438C35.1914 19.8438 33.9062 21.1289 33.9062 22.7812C33.9062 24.4336 35.1914 25.7188 36.8438 25.7188C38.4961 25.7188 39.7812 24.4336 39.7812 22.7812C39.7812 21.1289 38.4961 19.8438 36.8438 19.8438ZM22.8906 16.1719H19.9531V13.2344C19.9531 12.041 18.9434 11.0312 17.75 11.0312C16.5566 11.0312 15.5469 12.041 15.5469 13.2344V16.1719H12.6094C11.416 16.1719 10.4062 17.1816 10.4062 18.375C10.4062 19.5684 11.416 20.5781 12.6094 20.5781H15.5469V23.5156C15.5469 24.709 16.5566 25.7188 17.75 25.7188C18.9434 25.7188 19.9531 24.709 19.9531 23.5156V20.5781H22.8906C24.084 20.5781 25.0938 19.5684 25.0938 18.375C25.0938 17.1816 24.084 16.1719 22.8906 16.1719ZM42.7188 11.0312C41.0664 11.0312 39.7812 12.3164 39.7812 13.9688C39.7812 15.6211 41.0664 16.9062 42.7188 16.9062C44.3711 16.9062 45.6562 15.6211 45.6562 13.9688C45.6562 12.3164 44.3711 11.0312 42.7188 11.0312ZM41.25 0.75H17.75C8.01953 0.75 0.125 8.64453 0.125 18.375C0.125 28.1055 8.01953 36 17.75 36H41.25C50.9805 36 58.875 28.1055 58.875 18.375C58.875 8.64453 50.9805 0.75 41.25 0.75ZM41.25 31.5938H17.75C10.498 31.5938 4.53125 25.627 4.53125 18.375C4.53125 11.123 10.498 5.15625 17.75 5.15625H41.25C48.502 5.15625 54.4688 11.123 54.4688 18.375C54.4688 25.627 48.502 31.5938 41.25 31.5938Z'
        fill='#027AFF'
      />
    </svg>
  );
};
const GoPaperlessIcon = () => {
  return (
    <svg
      width='59'
      height='48'
      viewBox='0 0 59 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.4062 6.75C10.4062 6.01562 11.0488 5.28125 11.875 5.28125H41.25C41.9844 5.28125 42.7188 6.01562 42.7188 6.75V9.6875H47.125V6.75C47.125 3.53711 44.4629 0.875 41.25 0.875H11.875C8.57031 0.875 6 3.53711 6 6.75V30.25H1.59375C0.767578 30.25 0.125 30.9844 0.125 31.7188C0.125 34.1973 2.05273 36.125 4.53125 36.125H32.4375V30.25H10.4062V6.75ZM53 12.625H41.25C37.9453 12.625 35.375 15.2871 35.375 18.5V42C35.375 45.3047 37.9453 47.875 41.25 47.875H53C56.2129 47.875 58.875 45.3047 58.875 42V18.5C58.875 15.2871 56.2129 12.625 53 12.625ZM54.4688 42C54.4688 42.8262 53.7344 43.4688 53 43.4688H41.25C40.4238 43.4688 39.7812 42.8262 39.7812 42V18.5C39.7812 17.7656 40.4238 17.0312 41.25 17.0312H53C53.7344 17.0312 54.4688 17.7656 54.4688 18.5V42Z'
        fill='#027AFF'
      />
    </svg>
  );
};
const PayAsUUseIcon = () => {
  return (
    <svg
      width='42'
      height='48'
      viewBox='0 0 42 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.7188 15.5625V11.1562C10.7188 5.55664 15.3086 0.875 21 0.875C26.5996 0.875 31.2812 5.55664 31.2812 11.1562V15.5625H37.1562C39.543 15.5625 41.5625 17.582 41.5625 19.9688V39.0625C41.5625 43.9277 37.6152 47.875 32.75 47.875H9.25C4.29297 47.875 0.4375 43.9277 0.4375 39.0625V19.9688C0.4375 17.582 2.36523 15.5625 4.84375 15.5625H10.7188ZM15.125 15.5625H26.875V11.1562C26.875 7.94336 24.2129 5.28125 21 5.28125C17.6953 5.28125 15.125 7.94336 15.125 11.1562V15.5625ZM4.84375 19.9688V39.0625C4.84375 41.541 6.77148 43.4688 9.25 43.4688H32.75C35.1367 43.4688 37.1562 41.541 37.1562 39.0625V19.9688H31.2812V25.1094C31.2812 26.3945 30.2715 27.3125 29.0781 27.3125C27.793 27.3125 26.875 26.3945 26.875 25.1094V19.9688H15.125V25.1094C15.125 26.3945 14.1152 27.3125 12.9219 27.3125C11.6367 27.3125 10.7188 26.3945 10.7188 25.1094V19.9688H4.84375Z'
        fill='#027AFF'
      />
    </svg>
  );
};

const ScaleAndSpeedIcon = () => {
  return (
    <svg
      width='59'
      height='48'
      viewBox='0 0 59 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M29.5 16.9395C30.418 17.8574 30.418 19.2344 29.5 20.0605L22.1562 27.4043C21.3301 28.3223 19.9531 28.3223 19.127 27.4043C18.209 26.5781 18.209 25.2012 19.127 24.2832L22.707 20.7031H10.4062C9.12109 20.7031 8.20312 19.7852 8.20312 18.5C8.20312 17.3066 9.12109 16.2969 10.4062 16.2969H22.707L19.127 12.7168C18.209 11.8906 18.209 10.5137 19.127 9.6875C19.9531 8.76953 21.3301 8.76953 22.1562 9.6875L29.5 16.9395ZM44.1875 47.875C39.2305 47.875 35.375 43.9277 35.375 39.0625H23.625C23.625 43.9277 19.6777 47.875 14.8125 47.875C9.85547 47.875 6 43.9277 6 39.0625C2.69531 39.0625 0.125 36.4922 0.125 33.1875V6.75C0.125 3.53711 2.69531 0.875 6 0.875H32.4375C35.6504 0.875 38.3125 3.53711 38.3125 6.75V9.6875H42.168C43.5449 9.6875 44.8301 10.2383 45.8398 11.248L54.377 19.7852C55.3867 20.7949 55.9375 22.0801 55.9375 23.457V34.6562H56.6719C57.8652 34.6562 58.875 35.666 58.875 36.8594C58.875 38.1445 57.8652 39.0625 56.6719 39.0625H53C53 43.9277 49.0527 47.875 44.1875 47.875ZM4.53125 6.75V33.1875C4.53125 34.0137 5.17383 34.6562 6 34.6562H7.10156C8.66211 32.0859 11.5078 30.25 14.8125 30.25C18.0254 30.25 20.8711 32.0859 22.4316 34.6562H32.4375C33.1719 34.6562 33.9062 34.0137 33.9062 33.1875V6.75C33.9062 6.01562 33.1719 5.28125 32.4375 5.28125H6C5.17383 5.28125 4.53125 6.01562 4.53125 6.75ZM51.3477 22.9062C51.2559 22.9062 51.2559 22.9062 51.2559 22.9062L42.7188 14.3691C42.5352 14.1855 42.3516 14.0938 42.168 14.0938H38.3125V22.9062H51.3477ZM14.8125 43.4688C17.1992 43.4688 19.2188 41.541 19.2188 39.0625C19.2188 36.6758 17.1992 34.6562 14.8125 34.6562C12.334 34.6562 10.4062 36.6758 10.4062 39.0625C10.4062 41.541 12.334 43.4688 14.8125 43.4688ZM44.1875 34.6562C41.709 34.6562 39.7812 36.6758 39.7812 39.0625C39.7812 41.541 41.709 43.4688 44.1875 43.4688C46.5742 43.4688 48.5938 41.541 48.5938 39.0625C48.5938 36.6758 46.5742 34.6562 44.1875 34.6562Z'
        fill='#027AFF'
      />
    </svg>
  );
};

const IntegrationMadeEasyIcon = () => {
  return (
    <svg
      width='47'
      height='48'
      viewBox='0 0 47 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M41.125 0.875H30.8438C29.1914 0.875 27.9062 2.25195 27.9062 3.8125V8.21875H30.8438C32.4043 8.21875 33.7812 9.5957 33.7812 11.1562C33.7812 12.8086 32.4043 14.0938 30.8438 14.0938H27.9062V19.9688H33.7812V21.4375C33.7812 23.0898 35.0664 24.375 36.7188 24.375C38.2793 24.375 39.6562 23.0898 39.6562 21.4375V19.9688H44.0625C45.623 19.9688 47 18.6836 47 17.0312V6.75C47 3.53711 44.3379 0.875 41.125 0.875ZM38.1875 27.3125H33.7812V30.25C33.7812 31.9023 32.4043 33.1875 30.8438 33.1875C29.1914 33.1875 27.9062 31.9023 27.9062 30.25V27.3125H20.5625V33.1875H17.625C15.9727 33.1875 14.6875 34.5645 14.6875 36.125C14.6875 37.7773 15.9727 39.0625 17.625 39.0625H20.5625V43.4688H6.60938C5.32422 43.4688 4.40625 42.5508 4.40625 41.2656V27.3125H8.8125V24.375C8.8125 22.8145 10.0977 21.4375 11.75 21.4375C13.3105 21.4375 14.6875 22.8145 14.6875 24.375V27.3125H20.5625V19.9688H22.0312C23.5918 19.9688 24.9688 18.6836 24.9688 17.0312C24.9688 15.4707 23.5918 14.0938 22.0312 14.0938H20.5625V9.6875C20.5625 8.12695 19.1855 6.75 17.625 6.75H5.875C2.57031 6.75 0 9.41211 0 12.625V41.2656C0 44.9375 2.9375 47.875 6.60938 47.875H35.25C38.4629 47.875 41.125 45.3047 41.125 42V30.25C41.125 28.6895 39.748 27.3125 38.1875 27.3125Z'
        fill='#027AFF'
      />
    </svg>
  );
};

const ArchIcons = ({ icon, text }) => {
  return (
    <div>
      <div className='py-32 text-center'>{icon}</div>
      <div
        className='text-center'
        style={{
          fontSize: '24',
          fontFamily: 'Rubik',
          lineHeight: '190%',
          color: '#0B1623',
        }}
      >
        {text}
      </div>
    </div>
  );
};
const Section3 = () => {
  return (
    <Fragment>
      <div className='section-3-wrp'>
        <div className='d-flex justify-content-center'>
          <div style={{ width: '660px' }}>
            <div className='py-32  row'>
              <div className='col-6 col-md-4'>
                <ArchIcons icon={<OnTheCloudIcon />} text={'On The Cloud'} />
              </div>
              <div className='col-6 col-md-4'>
                <ArchIcons icon={<PlugAndPlayIcon />} text={'Plug and Play'} />
              </div>
              <div className='col-6 col-md-4'>
                <ArchIcons icon={<GoPaperlessIcon />} text={'Go Paperless'} />
              </div>
              <div className='col-6 col-md-4'>
                <ArchIcons icon={<PayAsUUseIcon />} text={'Pay as you Use'} />
              </div>
              <div className='col-6 col-md-4'>
                <ArchIcons
                  icon={<ScaleAndSpeedIcon />}
                  text={'Scale with speed and Clarity'}
                />
              </div>
              <div className='col-6 col-md-4'>
                <ArchIcons
                  icon={<IntegrationMadeEasyIcon />}
                  text={'Integrations, Made easy'}
                />
              </div>
            </div>
            <div className='py-48'>
              <div className='text-center' style={{ fontSize: '24' }}>
                Naadi’s API based Architecture provides seamless integration
                with upstream and downstream software systems (such as Design
                Tools, ERP systems.) via ready APIs.
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Section3;
