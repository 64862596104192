import React, { Fragment, useEffect, useState } from 'react';
import { NaadiLogo } from '../assets/icons';
import { useLocation } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';
import useMobile from '../hooks/useMobile';
const HeaderNavs = () => {
  const location = useLocation();
  //const history = useHistory();
  const [selected, setSelected] = useState('home');
  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith('/website/products')) {
      setSelected('products');
    } else if (path.startsWith('/website/reachus')) {
      setSelected('reachus');
    } else {
      setSelected('home');
    }
  }, [location.pathname]);
  return (
    <div className=''>
      <Nav className='ms-auto header-links-wrp '>
        <Nav.Link
          className={`link-level-1 cursor-pointer ${
            selected === 'home' ? 'selected' : ''
          }`}
          href='/website'
        >
          <div>Home</div>
        </Nav.Link>
        <NavDropdown
          title='Products'
          id='basic-nav-dropdown'
          className={`link-level-1 cursor-pointer ${
            selected === 'products' ? 'selected' : ''
          }`}
        >
          <NavDropdown.Item href='/website/products/boxnship'>
            <div className='header-nav-item'>Box n Ship</div>
          </NavDropdown.Item>
          <NavDropdown.Item href='/website/products/tracktrace'>
            <div className='header-nav-item'>Track n Trace</div>
          </NavDropdown.Item>
          <NavDropdown.Item href='/website/products/cutsmart'>
            <div className='header-nav-item'>Cut Smart</div>
          </NavDropdown.Item>
          <NavDropdown.Item href='/website/products/cache'>
            <div className='header-nav-item'>Cache</div>
          </NavDropdown.Item>
          <NavDropdown.Item href='/website/products/salt'>
            <div className='header-nav-item'>Salt</div>
          </NavDropdown.Item>
        </NavDropdown>
        <Nav.Link
          className={`link-level-1 cursor-pointer ${
            selected === 'reachus' ? 'selected' : ''
          }`}
          href='/website/reachus'
        >
          <div>Contact Us</div>
        </Nav.Link>
      </Nav>
    </div>
  );
};
const Header = () => {
  //eslint-disable-next-line
  const [isMobile, isTab] = useMobile();
  return (
    <Fragment>
      <div className='header-logo-row-wrp row'>
        <div className='header-logo-wrp'>
          <NaadiLogo />
        </div>
        <div className='col'>{isTab === false && <HeaderNavs />}</div>
        <div className='header-login-wrp justify-content-end'>
          <div className='cursor-pointer'>
            <a href='https://app.naadi.io/naadi-app/app/dashboard'>LOG IN</a>
          </div>
        </div>
        {isTab === true && (
          <div className='py-16 justify-content-end'>
            <HeaderNavs />
          </div>
        )}
      </div>
    </Fragment>
  );
};
export default Header;
