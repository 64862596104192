import React, { Fragment, useEffect, useState } from 'react';
import {
  AmedeoLogo,
  AnbLogo,
  BonitoLogo,
  BuiltInModularLogo,
  CraftVilleLogo,
  CreativeDesignLogo,
  DcurveLogo,
  DecoraLogo,
  DesignNestLogo,
  DmsDesignLogo,
  FineEdgeLogo,
  FormuraLogo,
  HangarSpaceLogo,
  HogarLogo,
  IbuildLogo,
  InqueLogo,
  JadeLogo,
  LivaKitchensLogo,
  MicroFactoryLogo,
  ModulinaLogo,
  OakTreeLogo,
  PlankLogo,
  PortalsLogo,
  PuranLogo,
  RakkiInteriorsLogo,
  SaviesaLogo,
  SpaceTechLogo,
  ViswaPanelcraftLogo,
  WertmobelLogo,
  WoodworldLogo,
  WudleyLogo,
  WudspaceLogo,
} from '../../assets/icons/brand-icons';
import { getOrgImages } from '../../services/web';
const partners = [
  { elem: <JadeLogo />, key: 'jade' },
  { elem: <AnbLogo />, key: 'anb' },
  { elem: <PortalsLogo />, key: 'portals' },
  { elem: <FormuraLogo />, key: 'formura' },
  { elem: <AmedeoLogo />, key: 'amedeo' },
  { elem: <WudspaceLogo />, key: 'wudspace' },
  { elem: <PuranLogo />, key: 'puran' },
  { elem: <BonitoLogo />, key: 'bonito' },
  { elem: <WertmobelLogo />, key: 'wertmobel' },
  { elem: <ViswaPanelcraftLogo />, key: 'viswapanelcraft' },
  { elem: <SaviesaLogo />, key: 'saviesa' },
  { elem: <HangarSpaceLogo />, key: 'hangarspace' },
  { elem: <OakTreeLogo />, key: 'oaktree' },
  { elem: <DcurveLogo />, key: 'dcurve' },
  { elem: <HogarLogo />, key: 'hogar' },
  { elem: <DesignNestLogo />, key: 'designnest' },
  { elem: <PlankLogo />, key: 'plank' },
  { elem: <CraftVilleLogo />, key: 'craftville' },
  { elem: <DmsDesignLogo />, key: 'dmsdesign' },
  { elem: <BuiltInModularLogo />, key: 'builtinmodular' },
  { elem: <CreativeDesignLogo />, key: 'CreativeDesign' },
  { elem: <DecoraLogo />, key: 'decora' },
  { elem: <FineEdgeLogo />, key: 'FineEdge' },
  { elem: <IbuildLogo />, key: 'Ibuild' },
  { elem: <InqueLogo />, key: 'inque' },
  { elem: <MicroFactoryLogo />, key: 'MicroFactory' },
  { elem: <LivaKitchensLogo />, key: 'LivaKitchens' },
  { elem: <ModulinaLogo />, key: 'modulina' },
  { elem: <RakkiInteriorsLogo />, key: 'RakkiInteriors' },
  { elem: <SpaceTechLogo />, key: 'SpaceTech' },
  { elem: <WoodworldLogo />, key: 'woodworld' },
  { elem: <WudleyLogo />, key: 'wudley' },
];

export const prefixImageUrl = size => {
  const imagePrefix = process.env.REACT_APP_IMAGE_PROXY_URL;
  return `${imagePrefix}/insecure/rs:fit:${size}:${size}:1/g:so/plain/`;
};
const OurPartners = () => {
  const [orgImages, setOrgImages] = useState([]);
  useEffect(() => {
    const fn = async () => {
      const images = await getOrgImages();
      if (images && images.status === 200) {
        const imagePrefix = prefixImageUrl(100);
        const displayImages = images.payload.map(val => {
          val.image = imagePrefix + val.image;
          return val;
        });
        setOrgImages(displayImages);
      }
    };
    fn();
  }, [setOrgImages]);
  return (
    <Fragment>
      <div className='our-partners-wrp'>
        <div className='home-page-content-header'>Our partners</div>
        <div className='py-32'>
          <div className='row'>
            {partners.map(val => {
              return (
                <div
                  className='col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2'
                  key={val.key}
                >
                  <div
                    style={{
                      width: '120px',
                      height: '120px',
                      position: 'relative',
                      margin: 'auto',
                    }}
                  >
                    {val.elem}
                  </div>
                </div>
              );
            })}

            {orgImages.map(val => {
              return (
                <div
                  className='col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2'
                  key={val.org_id}
                >
                  <div
                    className='d-flex align-items-center'
                    style={{
                      width: '120px',
                      height: '120px',
                      position: 'relative',
                      margin: 'auto',
                    }}
                  >
                    <img src={val.image} alt={val.org_id} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OurPartners;
