import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import { createStateSyncMiddleware } from 'redux-state-sync';
import storage from 'redux-persist-indexeddb-storage';

import reducers from './reducers';

const persistConfig = {
  key: 'website',
  storage: storage('myDB'),
  whitelist: [],
};
const reduxStateSyncConfig = {};

const persistedReducer = persistReducer(persistConfig, reducers);

const middlewares = [thunk, createStateSyncMiddleware(reduxStateSyncConfig)];

const composed = composeWithDevTools(applyMiddleware(...middlewares));

const store = createStore(persistedReducer, composed);
const persistor = persistStore(store);

const outerStore = { store, persistor };

export default outerStore;
export const getState = () => store.getState();
