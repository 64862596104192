import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, useLocation } from 'react-router-dom';

import { actions } from '@naadi/framework';
import Routes from './routes/index';
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
const App = props => {
  const dispatch = useDispatch();

  //const [sync, setSync] = useState(false);

  useEffect(() => {
    dispatch(actions.lang.change('en'));
  }, [dispatch]);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <Routes />
    </BrowserRouter>
  );
};

export default App;
