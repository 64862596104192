import { toast } from 'react-toastify';
import { ToastMessage } from '@naadi/framework';
import React from 'react';

export const toastError = message => {
  toastMessage({
    type: 'error',
    message: message,
    autoClose: 5000,
  });
};
export const toastWarning = message => {
  toastMessage({
    type: 'warn',
    message: message,
    autoClose: 5000,
  });
};
export const toastSuccess = message => {
  toastMessage({
    type: 'success',
    message: message,
    autoClose: 5000,
  });
};

export const toastMessage = val => {
  if (val.message === undefined || val.type === undefined) {
    return;
  }
  const options = {
    position: 'bottom-center',
    autoClose: val.autoClose ? val.autoClose : 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  toast.error(
    <ToastMessage message={val.message} type={val.type}></ToastMessage>,
    options
  );
};
