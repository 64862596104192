import React from 'react';
import sleeklogo from '../img/sleek-logo.png';
import jadelogo from '../img/jade-logo.png';
import anblogo from '../img/anb-logo.png';
import portalslogo from '../img/portals-logo.png';
import formuralogo from '../img/formura-logo.svg';
import amedeologo from '../img/amedeo-logo.svg';
import wudspacelogo from '../img/wudspace-logo.svg';
import puranlogo from '../img/puran-log.svg';
import bonitologo from '../img/bonito-logo.svg';
import wertmobellogo from '../img/wertmobel-logo.svg';
import viswapanelcraftlogo from '../img/viswa-panelcraft-logo.svg';
import saviesalogo from '../img/saviesa-logo.svg';
import hangarspacelogo from '../img/hangarspace-logo.svg';
import oaktreelogo from '../img/oaktree-logo.svg';
import dcurvelogo from '../img/dcurve-logo.svg';
import hogarlogo from '../img/hogar-logo.svg';
import designnestlogo from '../img/designnest-logo.svg';
import planklogo from '../img/plank-logo.svg';
import craftvillelogo from '../img/craftville-logo.svg';
import dmsdesignlogo from '../img/dms-design-logo.svg';
import woodworldlogo from '../img/woodworld-logo.svg';
import builtinmodularlogo from '../img/Built-in-modular-logo.svg';
import creativedesignlogo from '../img/creative-design.svg';
import decoralogo from '../img/Decora-logo.svg';
import fineedgelogo from '../img/fine-edge-logo.svg';
import ibuildlogo from '../img/ibuild-logo.svg';
import inquelogo from '../img/inque-logo.svg';
import modulinalogo from '../img/modulina-logo.svg';
import livakitchenslogo from '../img/liva-Kitchens-logo.svg';
import microfactorylogo from '../img/micro-factory-logo.svg';
import rakkiinteriorslogo from '../img/rakki-interiors-logo.svg';
import spacetechlogo from '../img/spacetech-logo.svg';
import wudleylogo from '../img/Wudley-logo.svg';

export const SleekLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={sleeklogo}
        alt={'sleek'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const JadeLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={jadelogo}
        alt={'sleek'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const AnbLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={anblogo}
        alt={'sleek'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const PortalsLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={portalslogo}
        alt={'sleek'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const FormuraLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={formuralogo}
        alt={'formura'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const AmedeoLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={amedeologo}
        alt={'amedeo'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const WudspaceLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={wudspacelogo}
        alt={'wudspace'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const PuranLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={puranlogo}
        alt={'puran'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const BonitoLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={bonitologo}
        alt={'bonito'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const WertmobelLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={wertmobellogo}
        alt={'wertmobel'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};
export const ViswaPanelcraftLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={viswapanelcraftlogo}
        alt={'viswapanelcraft'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};
export const SaviesaLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={saviesalogo}
        alt={'saviesa'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const HangarSpaceLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={hangarspacelogo}
        alt={'hargarspace'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const OakTreeLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={oaktreelogo}
        alt={'oaktree'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const DcurveLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={dcurvelogo}
        alt={'dcurve'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const HogarLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={hogarlogo}
        alt={'hogar'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const DesignNestLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={designnestlogo}
        alt={'designnest'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const PlankLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={planklogo}
        alt={'plank'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const CraftVilleLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={craftvillelogo}
        alt={'craftville'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};

export const DmsDesignLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={dmsdesignlogo}
        alt={'dmsdesign'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};
export const WoodworldLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={woodworldlogo}
        alt={'woodworld'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};
export const BuiltInModularLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={builtinmodularlogo}
        alt={'builtinmodular'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};
export const CreativeDesignLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={creativedesignlogo}
        alt={'creativedesign'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};
export const DecoraLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={decoralogo}
        alt={'decora'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};
export const FineEdgeLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={fineedgelogo}
        alt={'fineedge'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};
export const IbuildLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={ibuildlogo}
        alt={'Ibuild'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};
export const InqueLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={inquelogo}
        alt={'inque'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};
export const LivaKitchensLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={livakitchenslogo}
        alt={'LivaKitchens'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};
export const MicroFactoryLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={microfactorylogo}
        alt={'MicroFactory'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};
export const ModulinaLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={modulinalogo}
        alt={'modulina'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};
export const RakkiInteriorsLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={rakkiinteriorslogo}
        alt={'RakkiInteriors'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};
export const SpaceTechLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={spacetechlogo}
        alt={'SpaceTech'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};
export const WudleyLogo = () => {
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
      <img
        src={wudleylogo}
        alt={'wudley'}
        style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  );
};
