import React, { Fragment } from 'react';
import HomePage from '../views/home-page';

const Home = () => {
  return (
    <Fragment>
      <div style={{ padding: '0px' }} className='container-fluid'>
        <HomePage />
      </div>
    </Fragment>
  );
};

export default Home;
