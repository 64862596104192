import React, { Fragment } from 'react';
import cacheimage from '../assets/img/cache-image.png';
import Footer from '../components/footer';
import useMobile from '../hooks/useMobile';
import { ModulesComponent } from '../components/mainpage/section-1';
import { useHistory } from 'react-router-dom';
import { ProductStepIcon, ShowModuleContent, ShowModule } from './box-n-ship';
import WhatsAppChat from '../components/whatsup-link';

//const TEXT_RIGHT = 'text-right';
const JUSTIFY_CONTENT_END = 'justify-content-end';
const JUSTIFY_CONTENT_CENTER = 'justify-content-center';

export const CacheImage = ({ alignment, isMobile }) => {
  return (
    <div
      className={`d-flex ${
        isMobile
          ? 'justify-content-center'
          : alignment === 'right'
          ? JUSTIFY_CONTENT_END
          : alignment === 'left'
          ? ''
          : JUSTIFY_CONTENT_CENTER
      }`}
      style={{ maxHeight: '100%' }}
    >
      <img
        style={{
          maxHeight: '100%',
          maxWidth: '100%',
          height: '400px',
          objectFit: 'contain',
          objectPosition: alignment,
        }}
        alt='Cut Smart'
        src={cacheimage}
      />
    </div>
  );
};

const titleStyle = {
  paddingBottom: '16px',
  fontSize: '36px',
  fontColor: '#006EED',
  lineHeight: '42px',
};
const OffcutUtilizationIcon = () => {
  return (
    <svg
      width='37'
      height='38'
      viewBox='0 0 37 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.3125 6.125H19.9375C20.6758 6.125 21.25 5.55078 21.25 4.8125V2.1875C21.25 1.44922 20.6758 0.875 19.9375 0.875H17.3125C16.5742 0.875 16 1.44922 16 2.1875V4.8125C16 5.55078 16.5742 6.125 17.3125 6.125ZM25.1875 6.125H27.8125C28.5508 6.125 29.125 5.55078 29.125 4.8125V2.1875C29.125 1.44922 28.5508 0.875 27.8125 0.875H25.1875C24.4492 0.875 23.875 1.44922 23.875 2.1875V4.8125C23.875 5.55078 24.4492 6.125 25.1875 6.125ZM9.4375 6.125H12.0625C12.8008 6.125 13.375 5.55078 13.375 4.8125V2.1875C13.375 1.44922 12.8008 0.875 12.0625 0.875H9.4375C8.69922 0.875 8.125 1.44922 8.125 2.1875V4.8125C8.125 5.55078 8.69922 6.125 9.4375 6.125ZM1.5625 21.875H4.1875C4.92578 21.875 5.5 21.3008 5.5 20.5625V17.9375C5.5 17.1992 4.92578 16.625 4.1875 16.625H1.5625C0.824219 16.625 0.25 17.1992 0.25 17.9375V20.5625C0.25 21.3008 0.824219 21.875 1.5625 21.875ZM1.5625 14H4.1875C4.92578 14 5.5 13.4258 5.5 12.6875V10.0625C5.5 9.32422 4.92578 8.75 4.1875 8.75H1.5625C0.824219 8.75 0.25 9.32422 0.25 10.0625V12.6875C0.25 13.4258 0.824219 14 1.5625 14ZM1.5625 6.125H4.1875C4.92578 6.125 5.5 5.55078 5.5 4.8125V2.1875C5.5 1.44922 4.92578 0.875 4.1875 0.875H1.5625C0.824219 0.875 0.25 1.44922 0.25 2.1875V4.8125C0.25 5.55078 0.824219 6.125 1.5625 6.125ZM1.5625 29.75H4.1875C4.92578 29.75 5.5 29.1758 5.5 28.4375V25.8125C5.5 25.0742 4.92578 24.5 4.1875 24.5H1.5625C0.824219 24.5 0.25 25.0742 0.25 25.8125V28.4375C0.25 29.1758 0.824219 29.75 1.5625 29.75ZM35.6875 0.875H33.0625C32.3242 0.875 31.75 1.44922 31.75 2.1875V32.375H1.5625C0.824219 32.375 0.25 32.9492 0.25 33.6875V36.3125C0.25 37.0508 0.824219 37.625 1.5625 37.625H31.75C34.6211 37.625 37 35.2461 37 32.375V2.1875C37 1.44922 36.4258 0.875 35.6875 0.875Z'
        fill='#027AFF'
      />
    </svg>
  );
};
const OffcutUtilization = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={false}
          image={
            <ProductStepIcon
              alignment={'left'}
              isMobile={isMobile}
              isLeft={false}
              icon={<OffcutUtilizationIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={false}
              title={<div style={titleStyle}>Offcut Utlization</div>}
              content='Utilizes the offcuts in your inventory for optimization to reduce wastage and increase your profits '
            />
          }
        />
      </div>
    </div>
  );
};
const OffcutStorageIcon = () => {
  return (
    <svg
      width='49'
      height='43'
      viewBox='0 0 49 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M40.3711 21.25C39.3867 21.25 38.4023 21.0859 37.5 20.7578V31.75H11.25V20.7578C10.2656 21.0859 9.36328 21.25 8.29688 21.25C7.88672 21.25 7.39453 21.25 6.98438 21.168L6 20.9219V38.3125C6 40.4453 7.80469 42.25 9.9375 42.25H38.8125C41.0273 42.25 42.75 40.4453 42.75 38.3125V20.9219L41.7656 21.168C41.2734 21.25 40.8633 21.25 40.3711 21.25ZM45.7031 8.78125L40.9453 1.31641C40.5352 0.660156 39.7969 0.25 38.9766 0.25H9.77344C8.95312 0.25 8.21484 0.660156 7.80469 1.31641L3.04688 8.78125C0.667969 12.6367 2.80078 17.9688 7.3125 18.543C7.64062 18.625 7.96875 18.625 8.29688 18.625C10.4297 18.625 12.3984 17.7227 13.7109 16.2461C14.9414 17.7227 16.9102 18.625 19.043 18.625C21.1758 18.625 23.0625 17.7227 24.375 16.2461C25.6875 17.7227 27.5742 18.625 29.707 18.625C31.8398 18.625 33.7266 17.7227 35.0391 16.2461C36.3516 17.7227 38.2383 18.625 40.3711 18.625C40.7812 18.625 41.1094 18.625 41.4375 18.543C45.9492 17.9688 48.082 12.6367 45.7031 8.78125Z'
        fill='#027AFF'
      />
    </svg>
  );
};
const OffcutStorage = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={true}
          image={
            <ProductStepIcon
              alignment={'right'}
              isMobile={isMobile}
              isLeft={true}
              icon={<OffcutStorageIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={true}
              title={<div style={titleStyle}>Offcut Storage</div>}
              content='Storage system for easy storage and retrieval of offcuts'
            />
          }
        />
      </div>
    </div>
  );
};

const Cache = () => {
  const history = useHistory();
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <Fragment>
      <div className='d-none d-lg-block py-32'></div>
      <div className='px-32'>
        <div className='section-2-wrp' style={{ padding: '0px' }}>
          <div className='section-2-content' style={{ paddingTop: '0px' }}>
            <div className='pb-4'>
              <ShowModule
                isMobile={isMobile}
                isLeft={true}
                valign={'center'}
                image={
                  <CacheImage
                    alignment={'right'}
                    isMobile={isMobile}
                    isLeft={true}
                  />
                }
                content={
                  <ShowModuleContent
                    isMobile={isMobile}
                    isLeft={true}
                    title={<ModulesComponent name={'Cache'} />}
                    content='Offcut Management System'
                  />
                }
              />
            </div>
          </div>
          <OffcutUtilization />
          <OffcutStorage />
        </div>
        <div className='py-64'>
          <div className='text-center'>
            <div
              className='btn btn-outline-primary cursor-pointer'
              style={{ minWidth: '400px' }}
              onClick={() => {
                history.push('/website/reachus');
              }}
            >
              Book a Demo
            </div>
          </div>
          <WhatsAppChat />
          <div className='text-center ' style={{ paddingTop: '32px' }}>
            <div
              className='btn btn-ghost cursor-pointer'
              style={{ minWidth: '400px' }}
              onClick={() => {
                history.push('/website');
              }}
            >
              Home
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Cache;
