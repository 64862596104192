import { useState, useLayoutEffect } from 'react';

export const useMobile = () => {
  const [mobile, setMobile] = useState(false);
  const [tab, setTab] = useState(false);
  const [tabLandscape, setTabLandscape] = useState(false);
  const [lg, setLg] = useState(false);
  useLayoutEffect(() => {
    new ResizeObserver(() => {
      setMobile(window.innerWidth < 576);
      setTab(window.innerWidth < 768);
      setTabLandscape(window.innerWidth < 992);
      setLg(window.innerWidth < 1200);
    }).observe(document.body);
  }, []);

  return [mobile, tab, tabLandscape, lg];
};
export const useXl = () => {
  const [large, setLarge] = useState(false);
  useLayoutEffect(() => {
    new ResizeObserver(() => {
      setLarge(window.innerWidth > 1200);
    }).observe(document.body);
  }, []);
  return [large];
};
export default useMobile;
