import { combineReducers } from 'redux';

import { FrameworkReducers } from '@naadi/framework';

const { busy, lang } = FrameworkReducers;

export default combineReducers({
  busy,
  lang,
});
