import React, { Fragment } from 'react';
import Section1 from '../components/mainpage/section-1';
import Section2 from '../components/mainpage/section-2';
import Section3 from '../components/mainpage/section-3';
import GradientLine from '../components/mainpage/gradient-separator';
import OurPartners from '../components/mainpage/our-partners';
import OnboardingJourney from '../components/mainpage/onboarding-journey';
import Footer from '../components/footer';
import WhatsAppChat from '../components/whatsup-link';

const HomePage = () => {
  return (
    <Fragment>
      <div>
        <Section1 />
        <Section2 />
        <div className='py-32 px-32'>
          <GradientLine />
        </div>
        <WhatsAppChat />
        <Section3 />
        <div className='py-32 px-32'>
          <GradientLine />
        </div>
        <OurPartners />
        <div className='py-32 px-32'>
          <GradientLine />
        </div>
        <OnboardingJourney />
        <div className='py-32' />
        <Footer />
      </div>
    </Fragment>
  );
};
export default HomePage;
