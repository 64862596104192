import React, { Fragment } from 'react';
import useMobile from '../../hooks/useMobile';
import configuringnaadi from '../../assets/img/configuring-naadi-image.png';
import designtoolimage from '../../assets/img/dashboard-image.png';
import dashboardimage from '../../assets/img/dashboard-image.png';

import workflowimage from '../../assets/img/workflow-image.png';
import labeldesignimage from '../../assets/img/part-label-image.png';
import botsetupimage from '../../assets/img/bot-image.png';
import usertrainingimage from '../../assets/img/user-training-image.png';
import reportimage from '../../assets/img/reports-image.png';

const getAlignClass = align => {
  return align === 'right'
    ? 'text-right'
    : align === 'center'
    ? 'text-center'
    : '';
};
const KnowingYouData = ({ align }) => {
  const [isMobile, isTab, isTabLandscape] = useMobile();
  const _align = isMobile || isTab || isTabLandscape ? 'center' : align;
  return (
    <div className={`${getAlignClass(_align)}`}>
      <div className='onboarding-jrn-content-header'>
        Discovery - Knowing You
      </div>
      <div className='onboarding-jrn-content-text'>
        <span className='fw-bold'>AS IS Study</span> - Detailed documentation of
        your current process and systems, achieved through Observation,
        Interviews and Questionnaires. This acts as a baseline for further
        discussion with you to arrive at a TO-BE process document.
      </div>
    </div>
  );
};
const ConfiguringNaadi = ({ align }) => {
  const [isMobile, isTab, isTabLandscape] = useMobile();
  const _align = isMobile || isTab || isTabLandscape ? 'center' : align;
  return (
    <div>
      <div className={`${getAlignClass(_align)}`}>
        <img alt='configure naadi' src={configuringnaadi} height='190px' />
      </div>
      <div className={`${getAlignClass(_align)}`}>
        <div className='onboarding-jrn-content-header'>Configuring NAADI</div>
        <div className='onboarding-jrn-content-text'>
          This involves creating an Organization in NAADI and setting up all
          features, components and integrations that are required for you to
          successfully use NAADI.
        </div>
      </div>
    </div>
  );
};
const DesignToolIntegration = ({ align }) => {
  const [isMobile, isTab, isTabLandscape] = useMobile();
  const _align = isMobile || isTab || isTabLandscape ? 'center' : align;
  return (
    <div>
      <div className={`${getAlignClass(_align)}`}>
        <img
          className='image-max-width'
          alt='configure naadi'
          src={designtoolimage}
          height='190px'
        />
      </div>
      <div className={`${getAlignClass(_align)}`}>
        <div className='onboarding-jrn-content-header'>
          Design tool and Application integration
        </div>
        <div className='onboarding-jrn-content-text'>
          Brief phase where the NAADI development team works to integrate NAADI
          with other applications in your organization. Design Tool integration
          can be achieved for importing order level input information for
          manufacturing like cut list, Images and drilling programs directly to
          NAADI.
        </div>
      </div>
    </div>
  );
};
const WorkflowIntegration = ({ align }) => {
  const [isMobile, isTab, isTabLandscape] = useMobile();
  const _align = isMobile || isTab || isTabLandscape ? 'center' : align;
  return (
    <div>
      <div className={`${getAlignClass(_align)}`}>
        <img
          className='image-max-width'
          alt='configure naadi'
          src={workflowimage}
          height='136px'
        />
      </div>
      <div className={`${getAlignClass(_align)}`}>
        <div className='onboarding-jrn-content-header'>Workflow Set up</div>
        <div className='onboarding-jrn-content-text'>
          A customized Workflow for your organization sets up the sequence of
          operations for an item from ‘initiation to completion’. Packing Logic
          and Other validations are optional.
        </div>
      </div>
    </div>
  );
};
const LabelDesign = ({ align }) => {
  const [isMobile, isTab, isTabLandscape] = useMobile();
  const _align = isMobile || isTab || isTabLandscape ? 'center' : align;
  return (
    <div>
      <div className={`${getAlignClass(_align)}`}>
        <img
          className='image-max-width'
          alt='configure naadi'
          src={labeldesignimage}
          height='136px'
        />
      </div>
      <div className={`${getAlignClass(_align)}`}>
        <div className='onboarding-jrn-content-header'>Label Design</div>
        <div className='onboarding-jrn-content-text'>
          Fully customized part and box labels are designed for you. You can
          incorporate Logos or any other brand related information on the label
          design as desired.
        </div>
      </div>
    </div>
  );
};
const BotSetupStep = ({ align }) => {
  const [isMobile, isTab, isTabLandscape] = useMobile();
  const _align = isMobile || isTab || isTabLandscape ? 'center' : align;
  return (
    <div>
      <div className={`${getAlignClass(_align)}`}>
        <img
          className='image-max-width'
          alt='configure naadi'
          src={botsetupimage}
          height='80px'
        />
      </div>
      <div className={`${getAlignClass(_align)}`}>
        <div className='onboarding-jrn-content-header'>
          Hardware and Bot Setup
        </div>
        <div className='onboarding-jrn-content-text'>
          Once the internet requirements are met, hardwares like Desktops,
          laptops, tablets and printers are set up for use by our implementation
          team. Print Bots and Machine Integration bots are configured for
          NAADI.
        </div>
      </div>
    </div>
  );
};
const UserTrainingStep = ({ align }) => {
  const [isMobile, isTab, isTabLandscape] = useMobile();
  const _align = isMobile || isTab || isTabLandscape ? 'center' : align;
  return (
    <div>
      <div className={`${getAlignClass(_align)}`}>
        <img
          className='image-max-width'
          alt='configure naadi'
          src={usertrainingimage}
          height='197px'
        />
      </div>
      <div className={`${getAlignClass(_align)}`}>
        <div className='onboarding-jrn-content-header'>User Training</div>
        <div className='onboarding-jrn-content-text'>
          A direct, in-person comprehensive training on NAADI is given based on
          the roles and responsibilities of the user. This is achieved by
          maintaining clear communication and encouraging feedback.
        </div>
      </div>
    </div>
  );
};
const HandholdingStep = ({ align }) => {
  const [isMobile, isTab, isTabLandscape] = useMobile();
  const _align = isMobile || isTab || isTabLandscape ? 'center' : align;
  return (
    <div>
      <div className={`${getAlignClass(_align)}`}>
        <div className='onboarding-jrn-content-header'>Handholding</div>
        <div className='onboarding-jrn-content-text'>
          A brief phase where the users of the NAADI start to use it
          independently and yet the NAADI team is available merely as a
          guidance.
        </div>
      </div>
    </div>
  );
};
const ReportsAndDashboardStep = ({ align }) => {
  const [isMobile, isTab, isTabLandscape] = useMobile();
  const _align = isMobile || isTab || isTabLandscape ? 'center' : align;
  return (
    <div>
      <div className={`${getAlignClass(_align)}`}>
        <img alt='configure naadi' src={reportimage} height='210px' />
      </div>
      <div className={`${getAlignClass(_align)}`}>
        <div className='onboarding-jrn-content-header'>
          Reports and Dashboards
        </div>
        <div className='onboarding-jrn-content-text'>
          The report requirements are matched with standard reports and
          dashboards available in NAADI. Any new report requirement, if not part
          of the standard reports of the product will be taken up as a custom
          report, requirment.
        </div>
      </div>
    </div>
  );
};
const SignoffStep = ({ align }) => {
  const [isMobile, isTab, isTabLandscape] = useMobile();
  const _align = isMobile || isTab || isTabLandscape ? 'center' : align;
  return (
    <div>
      <div className={`${getAlignClass(_align)}`}>
        <div className='onboarding-jrn-content-header'>Sign off</div>
        <div className='onboarding-jrn-content-text'>
          Let the magic begin! Once you go Live with NAADI, It is time for us to
          take a Sign off. SLA guidelines are provided for down the line
          support.
        </div>
      </div>
    </div>
  );
};
const OnboardingJourney = () => {
  //eslint-disable-next-line
  //const [isMobile, isTab, isTabLandscape] = useMobile();
  return (
    <Fragment>
      <div className='onboarding-jrn-wrp'>
        <div className='home-page-content-header'>Onboarding Journey</div>
        <div className='onboarding-jrn-txt'>
          <div>It is not just about using NAADI. We go one step further! </div>
          <div>
            We integrate NAADI with your business flow to set you up for a Long
            Term success, right from the start. It is your experience that adds
            value to our product.
          </div>
        </div>
        <div>
          <div>
            <div className='d-flex '>
              <div className='flex-1 onboarding-jrn-content-sep px-32 d-none d-lg-block'></div>
              <div className='flex-1 px-32'>
                <KnowingYouData align={'left'} />
              </div>
            </div>
            <div className='d-flex onboarding-jrn-content-wrp'>
              <div className='flex-1 onboarding-jrn-content-sep px-32'>
                <ConfiguringNaadi align={'right'} />
              </div>
              <div className='flex-1 px-32 d-none d-lg-block'></div>
            </div>
            <div className='d-flex onboarding-jrn-content-wrp'>
              <div className='flex-1 onboarding-jrn-content-sep px-32 d-none d-lg-block'></div>
              <div className='flex-1 px-32'>
                <DesignToolIntegration align={'left'} />
              </div>
            </div>
            <div className='d-flex onboarding-jrn-content-wrp'>
              <div className='flex-1 onboarding-jrn-content-sep px-32'>
                <WorkflowIntegration align={'right'} />
              </div>
              <div className='flex-1 px-32 d-none d-lg-block'></div>
            </div>
            <div className={`d-flex onboarding-jrn-content-wrp`}>
              <div className='flex-1 onboarding-jrn-content-sep px-32 d-none d-lg-block'></div>
              <div className='flex-1 px-32'>
                <LabelDesign align={'left'} />
              </div>
            </div>
            <div className='d-flex onboarding-jrn-content-wrp'>
              <div className='flex-1 onboarding-jrn-content-sep px-32'>
                <BotSetupStep align={'right'} />
              </div>
              <div className='flex-1 px-32 d-none d-lg-block'></div>
            </div>
            <div className={`d-flex onboarding-jrn-content-wrp`}>
              <div className='flex-1 onboarding-jrn-content-sep px-32 d-none d-lg-block'></div>
              <div className='flex-1 px-32'>
                <UserTrainingStep align={'left'} />
              </div>
            </div>
            <div className='d-flex onboarding-jrn-content-wrp'>
              <div className='flex-1 onboarding-jrn-content-sep px-32'>
                <HandholdingStep align={'right'} />
              </div>
              <div className='flex-1 px-32 d-none d-lg-block'></div>
            </div>
            <div className={`d-flex onboarding-jrn-content-wrp`}>
              <div className='flex-1 onboarding-jrn-content-sep px-32 d-none d-lg-block'></div>
              <div className='flex-1 px-32'>
                <ReportsAndDashboardStep align={'left'} />
              </div>
            </div>
            <div className='d-flex onboarding-jrn-content-wrp'>
              <div className='flex-1 onboarding-jrn-content-sep px-32'>
                <SignoffStep align={'right'} />
              </div>
              <div className='flex-1 px-32 d-none d-lg-block'></div>
            </div>
          </div>
        </div>
        <div className='py-64 px-32'>
          <div className='w-100 py-32'>
            <img alt='dashboard' src={dashboardimage} width='100%' />
          </div>
        </div>
        <div className='px-32'>
          <div className='px-64 onboarding-signoff-wrp'>
            <div>We believe...</div>
            <div className='text-center pt-64 w-100'>
              One day we together will
            </div>
            <div
              className='text-center pt-64 w-100'
              style={{ fontSize: '82px', lineHeight: '96px' }}
            >
              transform the way we work
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default OnboardingJourney;
